exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-en-dashboard-js": () => import("./../../../src/pages/en/dashboard.js" /* webpackChunkName: "component---src-pages-en-dashboard-js" */),
  "component---src-pages-en-en-screening-js": () => import("./../../../src/pages/en/en-screening.js" /* webpackChunkName: "component---src-pages-en-en-screening-js" */),
  "component---src-pages-en-en-screening-malaria-js": () => import("./../../../src/pages/en/en-screening-malaria.js" /* webpackChunkName: "component---src-pages-en-en-screening-malaria-js" */),
  "component---src-pages-en-en-screening-tub-js": () => import("./../../../src/pages/en/en-screening-tub.js" /* webpackChunkName: "component---src-pages-en-en-screening-tub-js" */),
  "component---src-pages-en-favourites-js": () => import("./../../../src/pages/en/favourites.js" /* webpackChunkName: "component---src-pages-en-favourites-js" */),
  "component---src-pages-en-glossary-js": () => import("./../../../src/pages/en/glossary.js" /* webpackChunkName: "component---src-pages-en-glossary-js" */),
  "component---src-pages-en-help-js": () => import("./../../../src/pages/en/help.js" /* webpackChunkName: "component---src-pages-en-help-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-language-js": () => import("./../../../src/pages/en/language.js" /* webpackChunkName: "component---src-pages-en-language-js" */),
  "component---src-pages-en-login-js": () => import("./../../../src/pages/en/login.js" /* webpackChunkName: "component---src-pages-en-login-js" */),
  "component---src-pages-en-screening-js": () => import("./../../../src/pages/en/screening.js" /* webpackChunkName: "component---src-pages-en-screening-js" */),
  "component---src-pages-en-screening-select-js": () => import("./../../../src/pages/en/screening-select.js" /* webpackChunkName: "component---src-pages-en-screening-select-js" */),
  "component---src-pages-en-screenings-screening-hiv-js": () => import("./../../../src/pages/en/screenings/screening-hiv.js" /* webpackChunkName: "component---src-pages-en-screenings-screening-hiv-js" */),
  "component---src-pages-en-screenings-screening-malaria-js": () => import("./../../../src/pages/en/screenings/screening-malaria.js" /* webpackChunkName: "component---src-pages-en-screenings-screening-malaria-js" */),
  "component---src-pages-en-screenings-screening-tb-js": () => import("./../../../src/pages/en/screenings/screening-tb.js" /* webpackChunkName: "component---src-pages-en-screenings-screening-tb-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-nd-dashboard-js": () => import("./../../../src/pages/nd/dashboard.js" /* webpackChunkName: "component---src-pages-nd-dashboard-js" */),
  "component---src-pages-nd-favourites-js": () => import("./../../../src/pages/nd/favourites.js" /* webpackChunkName: "component---src-pages-nd-favourites-js" */),
  "component---src-pages-nd-glossary-js": () => import("./../../../src/pages/nd/glossary.js" /* webpackChunkName: "component---src-pages-nd-glossary-js" */),
  "component---src-pages-nd-help-js": () => import("./../../../src/pages/nd/help.js" /* webpackChunkName: "component---src-pages-nd-help-js" */),
  "component---src-pages-nd-index-js": () => import("./../../../src/pages/nd/index.js" /* webpackChunkName: "component---src-pages-nd-index-js" */),
  "component---src-pages-nd-language-js": () => import("./../../../src/pages/nd/language.js" /* webpackChunkName: "component---src-pages-nd-language-js" */),
  "component---src-pages-nd-login-js": () => import("./../../../src/pages/nd/login.js" /* webpackChunkName: "component---src-pages-nd-login-js" */),
  "component---src-pages-nd-nd-screening-js": () => import("./../../../src/pages/nd/nd-screening.js" /* webpackChunkName: "component---src-pages-nd-nd-screening-js" */),
  "component---src-pages-nd-nd-screening-malaria-js": () => import("./../../../src/pages/nd/nd-screening-malaria.js" /* webpackChunkName: "component---src-pages-nd-nd-screening-malaria-js" */),
  "component---src-pages-nd-nd-screening-tub-js": () => import("./../../../src/pages/nd/nd-screening-tub.js" /* webpackChunkName: "component---src-pages-nd-nd-screening-tub-js" */),
  "component---src-pages-nd-screening-js": () => import("./../../../src/pages/nd/screening.js" /* webpackChunkName: "component---src-pages-nd-screening-js" */),
  "component---src-pages-nd-screening-select-js": () => import("./../../../src/pages/nd/screening-select.js" /* webpackChunkName: "component---src-pages-nd-screening-select-js" */),
  "component---src-pages-nd-screenings-screening-hiv-js": () => import("./../../../src/pages/nd/screenings/screening-hiv.js" /* webpackChunkName: "component---src-pages-nd-screenings-screening-hiv-js" */),
  "component---src-pages-nd-screenings-screening-malaria-js": () => import("./../../../src/pages/nd/screenings/screening-malaria.js" /* webpackChunkName: "component---src-pages-nd-screenings-screening-malaria-js" */),
  "component---src-pages-nd-screenings-screening-tb-js": () => import("./../../../src/pages/nd/screenings/screening-tb.js" /* webpackChunkName: "component---src-pages-nd-screenings-screening-tb-js" */),
  "component---src-pages-nd-search-js": () => import("./../../../src/pages/nd/search.js" /* webpackChunkName: "component---src-pages-nd-search-js" */),
  "component---src-pages-sn-dashboard-js": () => import("./../../../src/pages/sn/dashboard.js" /* webpackChunkName: "component---src-pages-sn-dashboard-js" */),
  "component---src-pages-sn-favourites-js": () => import("./../../../src/pages/sn/favourites.js" /* webpackChunkName: "component---src-pages-sn-favourites-js" */),
  "component---src-pages-sn-glossary-js": () => import("./../../../src/pages/sn/glossary.js" /* webpackChunkName: "component---src-pages-sn-glossary-js" */),
  "component---src-pages-sn-help-js": () => import("./../../../src/pages/sn/help.js" /* webpackChunkName: "component---src-pages-sn-help-js" */),
  "component---src-pages-sn-index-js": () => import("./../../../src/pages/sn/index.js" /* webpackChunkName: "component---src-pages-sn-index-js" */),
  "component---src-pages-sn-language-js": () => import("./../../../src/pages/sn/language.js" /* webpackChunkName: "component---src-pages-sn-language-js" */),
  "component---src-pages-sn-login-js": () => import("./../../../src/pages/sn/login.js" /* webpackChunkName: "component---src-pages-sn-login-js" */),
  "component---src-pages-sn-screening-js": () => import("./../../../src/pages/sn/screening.js" /* webpackChunkName: "component---src-pages-sn-screening-js" */),
  "component---src-pages-sn-screening-select-js": () => import("./../../../src/pages/sn/screening-select.js" /* webpackChunkName: "component---src-pages-sn-screening-select-js" */),
  "component---src-pages-sn-screenings-screening-hiv-js": () => import("./../../../src/pages/sn/screenings/screening-hiv.js" /* webpackChunkName: "component---src-pages-sn-screenings-screening-hiv-js" */),
  "component---src-pages-sn-screenings-screening-malaria-js": () => import("./../../../src/pages/sn/screenings/screening-malaria.js" /* webpackChunkName: "component---src-pages-sn-screenings-screening-malaria-js" */),
  "component---src-pages-sn-screenings-screening-tb-js": () => import("./../../../src/pages/sn/screenings/screening-tb.js" /* webpackChunkName: "component---src-pages-sn-screenings-screening-tb-js" */),
  "component---src-pages-sn-search-js": () => import("./../../../src/pages/sn/search.js" /* webpackChunkName: "component---src-pages-sn-search-js" */),
  "component---src-pages-sn-sn-screening-js": () => import("./../../../src/pages/sn/sn-screening.js" /* webpackChunkName: "component---src-pages-sn-sn-screening-js" */),
  "component---src-pages-sn-sn-screening-malaria-js": () => import("./../../../src/pages/sn/sn-screening-malaria.js" /* webpackChunkName: "component---src-pages-sn-sn-screening-malaria-js" */),
  "component---src-pages-sn-sn-screening-tub-js": () => import("./../../../src/pages/sn/sn-screening-tub.js" /* webpackChunkName: "component---src-pages-sn-sn-screening-tub-js" */),
  "component---src-pages-start-screening-js": () => import("./../../../src/pages/start-screening.js" /* webpackChunkName: "component---src-pages-start-screening-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-sw-dashboard-js": () => import("./../../../src/pages/sw/dashboard.js" /* webpackChunkName: "component---src-pages-sw-dashboard-js" */),
  "component---src-pages-sw-favourites-js": () => import("./../../../src/pages/sw/favourites.js" /* webpackChunkName: "component---src-pages-sw-favourites-js" */),
  "component---src-pages-sw-glossary-js": () => import("./../../../src/pages/sw/glossary.js" /* webpackChunkName: "component---src-pages-sw-glossary-js" */),
  "component---src-pages-sw-help-js": () => import("./../../../src/pages/sw/help.js" /* webpackChunkName: "component---src-pages-sw-help-js" */),
  "component---src-pages-sw-index-js": () => import("./../../../src/pages/sw/index.js" /* webpackChunkName: "component---src-pages-sw-index-js" */),
  "component---src-pages-sw-language-js": () => import("./../../../src/pages/sw/language.js" /* webpackChunkName: "component---src-pages-sw-language-js" */),
  "component---src-pages-sw-login-js": () => import("./../../../src/pages/sw/login.js" /* webpackChunkName: "component---src-pages-sw-login-js" */),
  "component---src-pages-sw-screening-js": () => import("./../../../src/pages/sw/screening.js" /* webpackChunkName: "component---src-pages-sw-screening-js" */),
  "component---src-pages-sw-screening-select-js": () => import("./../../../src/pages/sw/screening-select.js" /* webpackChunkName: "component---src-pages-sw-screening-select-js" */),
  "component---src-pages-sw-screenings-screening-hiv-js": () => import("./../../../src/pages/sw/screenings/screening-hiv.js" /* webpackChunkName: "component---src-pages-sw-screenings-screening-hiv-js" */),
  "component---src-pages-sw-screenings-screening-malaria-js": () => import("./../../../src/pages/sw/screenings/screening-malaria.js" /* webpackChunkName: "component---src-pages-sw-screenings-screening-malaria-js" */),
  "component---src-pages-sw-screenings-screening-tb-js": () => import("./../../../src/pages/sw/screenings/screening-tb.js" /* webpackChunkName: "component---src-pages-sw-screenings-screening-tb-js" */),
  "component---src-pages-sw-search-js": () => import("./../../../src/pages/sw/search.js" /* webpackChunkName: "component---src-pages-sw-search-js" */),
  "component---src-pages-sw-sw-screening-js": () => import("./../../../src/pages/sw/sw-screening.js" /* webpackChunkName: "component---src-pages-sw-sw-screening-js" */),
  "component---src-pages-sw-sw-screening-malaria-js": () => import("./../../../src/pages/sw/sw-screening-malaria.js" /* webpackChunkName: "component---src-pages-sw-sw-screening-malaria-js" */),
  "component---src-pages-sw-sw-screening-tub-js": () => import("./../../../src/pages/sw/sw-screening-tub.js" /* webpackChunkName: "component---src-pages-sw-sw-screening-tub-js" */),
  "component---src-pages-testdb-js": () => import("./../../../src/pages/testdb.js" /* webpackChunkName: "component---src-pages-testdb-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-category-en-js": () => import("./../../../src/templates/category-en.js" /* webpackChunkName: "component---src-templates-category-en-js" */),
  "component---src-templates-category-sw-js": () => import("./../../../src/templates/category-sw.js" /* webpackChunkName: "component---src-templates-category-sw-js" */),
  "component---src-templates-helpslideshow-en-js": () => import("./../../../src/templates/helpslideshow-en.js" /* webpackChunkName: "component---src-templates-helpslideshow-en-js" */),
  "component---src-templates-myth-en-js": () => import("./../../../src/templates/myth-en.js" /* webpackChunkName: "component---src-templates-myth-en-js" */),
  "component---src-templates-myth-sw-js": () => import("./../../../src/templates/myth-sw.js" /* webpackChunkName: "component---src-templates-myth-sw-js" */),
  "component---src-templates-questions-en-js": () => import("./../../../src/templates/questions-en.js" /* webpackChunkName: "component---src-templates-questions-en-js" */),
  "component---src-templates-questions-sw-js": () => import("./../../../src/templates/questions-sw.js" /* webpackChunkName: "component---src-templates-questions-sw-js" */),
  "component---src-templates-quiz-en-js": () => import("./../../../src/templates/quiz-en.js" /* webpackChunkName: "component---src-templates-quiz-en-js" */),
  "component---src-templates-quiz-sw-js": () => import("./../../../src/templates/quiz-sw.js" /* webpackChunkName: "component---src-templates-quiz-sw-js" */),
  "component---src-templates-slideshow-en-js": () => import("./../../../src/templates/slideshow-en.js" /* webpackChunkName: "component---src-templates-slideshow-en-js" */),
  "component---src-templates-slideshow-sw-js": () => import("./../../../src/templates/slideshow-sw.js" /* webpackChunkName: "component---src-templates-slideshow-sw-js" */),
  "component---src-templates-unit-en-js": () => import("./../../../src/templates/unit-en.js" /* webpackChunkName: "component---src-templates-unit-en-js" */),
  "component---src-templates-unit-sw-js": () => import("./../../../src/templates/unit-sw.js" /* webpackChunkName: "component---src-templates-unit-sw-js" */)
}

